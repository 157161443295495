import { createGlobalStyle } from 'styled-components'
import { xltGrey, darkGrey, primary } from '../elements'
import { sansCondBold, sansBoldItalic, sans } from '../utilities'
import map from '../images/usa-map.png'

const GlobalStyle = createGlobalStyle`
  /*! Mashup of normalize.css and Eric Meyer */
  html {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    line-height: 1.15; 
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body {
    margin: 0;
  }
  html {
    box-sizing: border-box;
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  main,
  menu,
  nav,
  section,
  summary {
    display: block;
  }
  audio,
  canvas,
  progress,
  video {
    display: inline-block;
  }
  audio:not([controls]) {
    display: none;
    height: 0;
  }
  progress {
    vertical-align: baseline;
  }
  [hidden],
  template {
    display: none;
  }
  main {
    display: block;
  }
  h1, h2, h3, h4, h5, h6, p {
    font-size: inherit;
    font-weight: inherit;
    margin: 0;
    padding: 0;
  }
  hr {
    box-sizing: content-box; 
    height: 0; 
    overflow: visible;
  }
  pre {
    font-family: monospace, monospace; 
    font-size: 1rem;
  }
  a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
  }
  a:active,
  a:hover {
    outline-width: 0;
  }
  abbr[title] {
    border-bottom: 1px dotted hsla(0, 0%, 0%, 0.5);
    cursor: help;
    text-decoration: none;
  }
  b,
  strong {
    font-weight: bolder;
  }
  dfn {
    font-style: italic;
  }
  code,
  kbd,
  pre,
  samp {
    font-family: monospace, monospace; 
    font-size: 1rem;
  }
  small {
    font-size: 80%;
  }
  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }
  sub {
    bottom: -0.25em;
  }
  sup {
    top: -0.5em;
  }
  img {
    border-style: none;
  }
  svg:not(:root) {
    overflow: hidden;
  }
  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit;
    font-size: 1rem;
    margin: 0;
  }
  button,
  input { 
    overflow: visible;
  }
  button,
  select { 
    text-transform: none;
  }
  [type='reset'],
  [type='submit'],
  button,
  html [type='button'] {
    -webkit-appearance: button;
  }
  [type='button']::-moz-focus-inner,
  [type='reset']::-moz-focus-inner,
  [type='submit']::-moz-focus-inner,
  button::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }
  [type='button']:-moz-focusring,
  [type='reset']:-moz-focusring,
  [type='submit']:-moz-focusring,
  button:-moz-focusring {
    outline: 1px dotted ButtonText;
  }
  fieldset {
    border: 1px solid silver;
    padding: 0.35em 0.75em 0.625em;
    margin-bottom: 1.45rem;
  }
  legend {
    box-sizing: border-box; 
    color: inherit;
    display: table; 
    max-width: 100%; 
    padding: 0; 
    white-space: normal; 
  }
  progress {
    vertical-align: baseline;
  }
  textarea {
    overflow: auto;
  }
  [type='checkbox'],
  [type='radio'] {
    box-sizing: border-box;
    padding: 0;
  }
  [type='number']::-webkit-inner-spin-button,
  [type='number']::-webkit-outer-spin-button {
    height: auto;
  }
  [type='search'] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
  }
  [type='search']::-webkit-search-cancel-button,
  [type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  ::-webkit-input-placeholder {
    color: inherit;
    opacity: 0.54;
  }
  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
  }
  details {
    display: block;
  }
  summary {
    display: list-item;
  }
  template {
    display: none;
  }
  [hidden] {
    display: none;
  }

  /*! Setup basic defaults */
   /* Added to Fix Footer to bottom of viewport */
   html, body {
    height: 100%;
  }
  .siteRoot {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  .siteContent {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
  }
  footer {
    width: 100%;
  }
  /* End Fix to Place Footer on Bottom of Viewport */
  body {
    font-weight: normal;
    word-wrap: break-word;
    font-kerning: normal;
    color: ${darkGrey};
    ${sans};
    -moz-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
    -ms-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
    -webkit-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
    font-feature-settings: 'kern', 'liga', 'clig', 'calt';
    background: ${xltGrey} url(${map}) no-repeat center top;
  }
  img {
    max-width: 100%;
    height: auto;
    margin: 0;
    padding:0;
  }
  h1, h2, h3, h4, h5, h6, p {
    color: inherit;
    text-rendering: optimizeLegibility;
    margin-bottom: 1rem;
  }
  ul, ol {
    list-style-position: outside;
    list-style-image: none;
    padding-left: 1.42rem;
  }
  li {
    line-height: 1.5rem;
  }
  pre {
    margin: 0 0 1.45rem 0;
    line-height: 1.42;
    background: hsla(0, 0%, 0%, 0.04);
    border-radius: 3px;
    overflow: auto;
    word-wrap: normal;
    padding: 1.45rem;
  }
  table {
    margin: 0 0 1.45rem 0;
    padding: 0;
    font-size: 1rem;
    line-height: 1.45;
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }
  thead {
    text-align: left;
  }
  td,
  th {
    text-align: left;
    border-bottom: 1px solid hsla(0, 0%, 0%, 0.12);
    font-feature-settings: 'tnum';
    -moz-font-feature-settings: 'tnum';
    -ms-font-feature-settings: 'tnum';
    -webkit-font-feature-settings: 'tnum';
    padding-left: 0.96667rem;
    padding-right: 0.96667rem;
    padding-top: 0.725rem;
    padding-bottom: calc(0.725rem - 1px);
  }
  th:first-child,
  td:first-child {
    padding-left: 0;
  }
  th:last-child,
  td:last-child {
    padding-right: 0;
  }
  tt,
  code {
    background-color: hsla(0, 0%, 0%, 0.04);
    border-radius: 3px;
    font-family: 'SFMono-Regular', Consolas, 'Roboto Mono', 'Droid Sans Mono', 'Liberation Mono', Menlo, Courier, monospace;
    padding: 0;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
  }
  pre code {
    background: none;
    line-height: 1.42;
  }
  code:before,
  code:after,
  tt:before,
  tt:after {
    letter-spacing: -0.2em;
    content: ' ';
  }
  pre code:before,
  pre code:after,
  pre tt:before,
  pre tt:after {
    content: '';
  }
  blockquote, form, noscript, iframe, hr, address {
    margin: 0 0 1.45rem 0;
    padding: 0;
  }
  address {
    font-style: normal;
  }
  hr {
    margin-bottom: calc(1.45rem - 1px);
    /* Research setting the background on hr */
    background: hsla(0, 0%, 0%, 0.2);
  }
  b, strong, dt, th {
    font-weight: bold;
  }
  em { font-style: italic; }
  /* Hack scrollbar padding and margin on mobile */
  @media screen and (min-width: 35em) {
    html {
      margin-right: calc(-100vw + 100%);
      overflow-x: hidden;
      /* Look into the margin-right hack, might not be good on mobile */
      margin-right: 0; 
    }
  }
  h1 {
    ${sansCondBold};
    font-size: 4rem;
  }
  h2 {
    ${sansCondBold};
    font-size: 3rem;
  }
  h3 {
    ${sans};
    font-size: 1.85rem;
  }
  h4 {
    ${sansCondBold};
    font-size: 1.85rem;
  }
  h5 {
    ${sansCondBold};
    font-size: 1.15rem;
    text-transform: uppercase;
  }
  h6 {
    ${sans};
    font-size: 1.15rem;
  }
  p {
    ${sans};
    font-size: 1.15rem;
    line-height: 1.75rem;
  }
  blockquote {
    padding: 0 0 1rem 32px;
    position: relative;
    color: white;
    text-align: left;
    p {
      font-size: 1.35rem;
      line-height: 1.85rem;
    }
    &:before {
      ${sansBoldItalic};
      content: '"';
      color: ${primary};
      font-size: 4rem;
      position: absolute;
      left: -8px;
      top: -12px;
    }
  }
  cite,
  cite a {
    font-size: 1.15rem;
    text-decoration: none;
    color: ${primary};
    &:hover {
      color: white;
    }
  }
`
export default GlobalStyle
