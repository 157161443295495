import { css } from 'styled-components'
import '../fonts/fonts.css'

export const sans = () => css`
  font-family: 'Trade Gothic LT W01 Roman';
`
export const sansItalic = () => css`
  font-family: 'Trade Gothic LT W01 Oblique';
`
export const sansCondBold = () => css`
  font-family: 'Trade Gothic LT W01 Bold';
`
export const sansBold = () => css`
  font-family: 'Trade Gothic LT W01 Bold No-_2';
`
export const sansBoldItalic = () => css`
  font-family: 'Trade Gothic LT W01 Bd No-2Obl';
`
export const sansCond = () => css`
  font-family: 'Trade Gothic LT W01 Cn No-_18';
`
