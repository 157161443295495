import React, { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { above, primary, darkGrey } from '../elements'
import { sansBold, sansCond } from '../utilities'

const Links = [
  {
    link: '/shippers',
    label: 'Shippers',
  },
  {
    link: '/carriers',
    label: 'Carriers',
  },
  {
    link: '/drivers',
    label: 'Drivers',
  },
  {
    link: '/about',
    label: 'About',
  },
  {
    link: '/culture',
    label: 'Culture',
  },
  {
    link: '/careers',
    label: 'Careers',
  },
  {
    link: '/contact',
    label: 'Contact',
  },
]

export default class Nav extends Component {
  render() {
    const { toggle } = this.props
    return (
      <Navigation>
        <>
          <NavButton onClick={toggle}>Close Menu</NavButton>
          {Links.map(({ link, label }) => (
            <Link activeClassName="active" to={link} key={link}>
              {label}
            </Link>
          ))}
          <NavContact>
            <a href="tel:+18002237671" rel="nofollow">
              <i className="material-icons">phone_iphone</i>&nbsp;(800) 223-7671
            </a>
            <a href="/contact">
              <i className="material-icons">place</i>
              &nbsp;Request a Quote
            </a>
            <a
              rel="noopener noreferrer"
              href="https://intelliapp.driverapponline.com/c/ddtsi"
              target="_blank"
            >
              <i className="material-icons">chrome_reader_mode</i>&nbsp; Driver
              Application
            </a>
            <a href="https://ddta.loadtracking.com:8878/im/home">
              <i className="material-icons">lock_outline</i>&nbsp;Login
            </a>
          </NavContact>
        </>
      </Navigation>
    )
  }
}

const Navigation = styled.nav`
  background: ${primary};
  color: ${darkGrey};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 2rem 5%;
  z-index: 10;
  a {
    display: block;
    text-align: center;
    font-weight: normal;
    font-size: 24px;
    color: ${darkGrey};
    margin-bottom: 1.2rem;
    margin-top: 1.2rem;
    text-decoration: none;
    &:hover {
      color: white;
    }
    &.active {
      ${sansBold};
      &:before {
        font-family: 'Material Icons';
        font-size: 18px;
        content: 'keyboard_arrow_right';
        color: ${darkGrey};
        padding-right: 0.15rem;
        margin-left: -0.5rem;
      }
    }
  }
`
const NavButton = styled.button`
  margin: 0 auto 2.2rem;
  display: block;
  ${above.md`
    display: none;
  `}
`

const NavContact = styled.nav`
  /* display: flex;
  flex: 1 1 auto;
  justify-content: flex-start;
  width: 100%;
  align-items: flex-start; */
  margin-top: 2rem;
  border-top: 1px solid ${darkGrey};
  padding-top: 1rem;
  a {
    ${sansCond}
    font-size: 1rem;
    color: ${darkGrey};
    text-transform: uppercase;
    text-decoration: none;
    font-weight: normal;
    transition: 0.3s ease border;
    padding: 0;
    text-align: center;
    &:hover,
    &.active {
      color: ${darkGrey};
    }
  }
`
