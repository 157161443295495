import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { above, primary, darkGrey, grey, ltGrey, mdGrey } from '../elements'
import Newsletter from '../components/Newsletter'
import { sansCond, sansCondBold } from '../utilities'

import logogray from '../images/wgat-stacked.svg'
import SocialIcons from './SocialIcons'

const Footer = () => (
  <FooterWrapper>
    <FooterContainer>
      <FooterBlock>
        <Link
          to="/"
          style={{
            color: 'white',
            textDecoration: 'none',
          }}
        >
          <img
            src={logogray}
            alt="D&D Transportation Services"
            style={{ width: '136px', marginBottom: '16px' }}
          />
        </Link>
      </FooterBlock>
      <FooterBlock>
        <FooterColumn>
          <Address>
            1735 Main Street
            <br />
            PO Box 116
            <br />
            Gooding, Idaho 83330
          </Address>
        </FooterColumn>
        <FooterColumn>
          <Contact>
            <ContactItem>
              <a href="tel:+18002237671" rel="nofollow">
                (800) 223-7671
              </a>
            </ContactItem>
            <ContactItem>
              <a href="tel:+01-208-934-4451" rel="nofollow">
                (208) 934-4451
              </a>
            </ContactItem>
            <ContactItem>
              <a href="mailto:info@ddtsi.com">info@ddtsi.com</a>
            </ContactItem>
          </Contact>
        </FooterColumn>
      </FooterBlock>
      <FooterBlock>
        <FooterColumn>
          <List>
            <Item>
              <a href="/shippers">Shippers</a>
            </Item>
            <Item>
              <a href="/carriers">Carriers</a>
            </Item>
            <Item>
              <a href="/drivers">Drivers</a>
            </Item>
          </List>
        </FooterColumn>
        <FooterColumn>
          <List>
            <Item>
              <a href="/about">About</a>
            </Item>
            <Item>
              <a href="/culture">Culture</a>
            </Item>
            <Item>
              <a href="/careers">Careers</a>
            </Item>
          </List>
        </FooterColumn>
      </FooterBlock>
      <FooterBlock>
        <Newsletter />
      </FooterBlock>
    </FooterContainer>
    <FooterCopyright>
      <p>
        {'\u00A9'} {new Date().getFullYear()} D&D Transportation Services, Inc.
        All rights reserved. <a href="/privacy">Privacy</a>{' '}
        <a href="/terms-of-use">Terms</a>
      </p>
      <SocialIcons />
    </FooterCopyright>
  </FooterWrapper>
)

export default Footer

const FooterWrapper = styled.footer`
  background-color: ${darkGrey};
  width: 100%;
  /* max-width: ${props => props.theme.sizes.maxWidth}; */
`
const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${props => props.theme.sizes.maxWidth};
  margin: 1rem auto 0;
  padding: 1rem;
  color: ${ltGrey};
  ${above.md`
    flex-direction: row;
    justify-content: space-between;
  `}
`
const FooterCopyright = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${props => props.theme.sizes.maxWidth};
  margin: 0 auto;
  padding: 1rem 0 2rem 1rem;
  border-top: 1px solid ${mdGrey};
  color: white;
  ${above.md`
    flex-direction: row;
    justify-content: space-between;
  `}
  p {
    ${sansCond};
    color: ${grey};
    line-height: 1.4em;
    text-align: center;
    ${above.md`
      text-align: left;
    `}
    a {
      color: ${grey};
      transition: all 0.2s;
      text-decoration: none;
      padding-left: 1rem;
      &:hover {
        color: ${primary};
      }
    }
  }
`
const FooterBlock = styled.div`
  display: flex;
  margin: 0 auto;
  ${above.md`
    flex-direction: column;
  `}
  ${above.lg`
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
  `}
`
const FooterColumn = styled.div`
  flex-direction: column;
  align-items: flex-start;
  margin: 0 1em 2em 1em;
  ${above.md`
    margin-bottom: 0;
  `}
  ${above.lg`
    margin-bottom: 2rem;
  `}
`
const List = styled.ul`
  display: flex;
  flex-flow: column;
  /* justify-content: space-between; */
  width: 100%;
  padding: 0;
  margin: 0;
  /* background: magenta; */
  ${above.lg`
    /* background: red; */
  `}
`
const Item = styled.li`
  display: inline-block;
  padding: 0 0 1em 0;
  width: 100%;
  a,
  &:visited {
    ${sansCondBold}
    transition: all 0.2s;
    font-size: 1rem;
    letter-spacing: 0.05em;
    color: ${grey};
    text-transform: uppercase;
    text-decoration: none;
    &:hover {
      color: ${primary};
    }
  }
`
const Address = styled.address`
  ${sansCond};
  color: ${ltGrey};
  line-height: 1.4em;
  letter-spacing: 0.05em;
`
const Contact = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`
const ContactItem = styled.li`
  a {
    ${sansCond};
    color: ${ltGrey};
    transition: all 0.2s;
    line-height: 1.4em;
    letter-spacing: 0.05em;
    text-decoration: none;
    &:hover {
      color: ${primary};
    }
  }
`
