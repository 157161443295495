export const primary = '#F9A825' // D&D Gold
export const secondary = '#28688B' // Slate Blue
export const tertiary = '#4D7925' // Earth Green
export const darkGrey = '#2A2927' // Black
export const richGrey = '#3C3A38' // Dark Grey
export const mdGrey = '#7E7C7A' // Medium Grey
export const grey = '#AEACAA' // Grey
export const ltGrey = '#D0D0CE' // Light Grey
export const xltGrey = '#F7F6F5' // Super Light Grey
export const danger = '#C92A25' // Red

export default {
  primary,
  secondary,
  tertiary,
  darkGrey,
  richGrey,
  mdGrey,
  grey,
  ltGrey,
  xltGrey,
  danger,
}

export const rgba = (hax, alpha) => {
  let hex = hax
  hex = hex.replace('#', '')
  const r = parseInt(
    hex.length === 3 ? hex.slice(0, 1).repeat(2) : hex.slice(0, 2),
    16
  )
  const g = parseInt(
    hex.length === 3 ? hex.slice(1, 2).repeat(2) : hex.slice(2, 4),
    16
  )
  const b = parseInt(
    hex.length === 3 ? hex.slice(2, 3).repeat(2) : hex.slice(4, 6),
    16
  )
  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`
  }
  return `rgb(${r}, ${g}, ${b})`
}
