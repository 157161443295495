import styled from 'styled-components'
import { above } from './breakpoints'
import { primary, xltGrey } from './colors'

export const FlexList = styled.ul`
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
  margin: 0;
  padding: 0;
  ${above.md`
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
  `};
`
export const FlexItem = styled.li`
  list-style-type: none;
  display: inline-block;
  width: 100%;
  padding: 1rem;
  margin-bottom: 1rem;
  background: ${xltGrey};
  text-align: left;
`

export const BenefitsSidebarList = styled.ul`
  list-style-type: none;
  padding-left: 0;
`

export const BenefitsItem = styled.li`
  font-size: 1.15rem;
  padding-bottom: 0.75rem;
  .material-icons {
    font-size: 24px;
    color: ${primary};
  }
`
