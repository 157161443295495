import React, { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { above, below, primary, darkGrey } from '../elements'
import { sansCondBold } from '../utilities'

const Links = [
  {
    link: '/shippers',
    label: 'Shippers',
  },
  {
    link: '/carriers',
    label: 'Carriers',
  },
  {
    link: '/drivers',
    label: 'Drivers',
  },
  {
    link: '/about',
    label: 'About',
  },
  {
    link: '/culture',
    label: 'Culture',
  },
  {
    link: '/careers',
    label: 'Careers',
  },
  {
    link: '/contact',
    label: 'Contact',
  },
]

export default class Nav extends Component {
  render() {
    return (
      <Navigation>
        {Links.map(({ link, label }) => (
          <Link activeClassName="active" to={link} key={link}>
            {label}
          </Link>
        ))}
      </Navigation>
    )
  }
}

const Navigation = styled.nav`
  display: none;
  ${above.md`
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
    width: 100%;
    align-items: flex-end;
    a {
      ${sansCondBold}
      font-size: 1rem;
      letter-spacing: 0.025em;
      color: ${darkGrey};
      text-transform: uppercase;
      text-decoration: none;
      font-weight: normal;
      border-top: solid 4px transparent;
      transition: 0.3s ease border;
      padding: 6px 0 0 0;
      margin-left: 2rem;
      &:hover,
      &.active {
        border-top: solid 4px ${primary};
        color: ${primary};
      }
    }
  `};
  ${below.lg`
    a {
      margin-left: 1.25rem;
    }
  `}
`
