import { css } from 'styled-components'

const sizes = {
  // xsmall: 340,
  // small: 500,
  // med: 800,
  // large: 960,
  // wide: 1216,
  xs: 320, // Mobile
  sm: 650, // Mobile-Lg
  md: 768, // Tablet
  lg: 1024, // Laptop
  xl: 1440, // Desktop
}

export const above = Object.keys(sizes).reduce((accumulator, label) => {
  const emSize = sizes[label] / 16
  accumulator[label] = (...args) => css`
    @media (min-width: ${emSize}em) {
      ${css(...args)};
    }
  `
  return accumulator
}, {})

export const below = Object.keys(sizes).reduce((accumulator, label) => {
  const emSize = sizes[label] / 16
  accumulator[label] = (...args) => css`
    @media (max-width: ${emSize}em) {
      ${css(...args)};
    }
  `
  return accumulator
}, {})
