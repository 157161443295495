import { applyStyleModifiers } from 'styled-components-modifiers'
import styled from 'styled-components'
import { above, below } from './breakpoints'
import { ltGrey } from './colors'

export const SplitLayout = styled.div`
  ${above.md`
    display: flex;
    justify-content: space-between;
  `};
`
export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  > * {
    margin-right: 2%;
    width: 48%;
    &:nth-child(2) {
      margin-right: 0;
    }
  }
  ${above.md`
    > * {
      margin-right: 2%;
      width: 23%;
      &:nth-child(2) {
        margin-right: 2%;
      }
      &:nth-child(4) {
        margin-right: 0;
      }
    }
  `}
`

const MODIFIER_ZONE = {
  light: () => `
    background: white;
  `,
  translucent: () => `
    background: rgba(255, 255, 255, 0.4);
  `,
}

export const SidebarLayout = styled.div`
  margin: 3rem 0;
  .benefits-sidebar {
    /* border: 1px solid ${ltGrey}; */
    padding: 2rem 2rem 1rem 2rem;
    text-align: center;
    margin-top: 2rem;
    -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1);
    -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1);
    transition: background 0.2s;
    ${applyStyleModifiers(MODIFIER_ZONE)};
  }
  ${above.lg`
    display: flex;
    > * { 
      flex: 1 
    }
    .benefits-sidebar {
      margin-left: 2rem;
      flex: 0 1 36%;
      text-align: left;
      margin-top: 0;
    }
  `}
  ${below.sm`
    .benefits-sidebar { text-align: left; }
  `}
`

export const CultureLayout = styled.div`
  margin: 4rem auto;
  max-width: ${props => props.theme.sizes.maxWidth};
  .left-sidebar {
    padding: 0 2rem 1rem 2rem;
  }
  ${above.lg`
    display: flex;
    > * { 
      flex: 1 
    }
    .left-sidebar {
      margin-right: 2rem;
      flex: 0 1 36%;
      text-align: left;
      margin-top: 0;
    }
  `}
`
