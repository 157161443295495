import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Nav from './Nav'
import MobileNav from './MobileNav'
import { above, primary, darkGrey, ltGrey, Toggle } from '../elements'
import { sansCond, sansCondBold } from '../utilities'

import logoinsignia from '../images/ddtsi-insignia.svg'
import logo from '../images/ddtsi-logo-tagline.svg'

const activeLinkStyle = {
  color: 'white',
}

const Menu = () => (
  <HeaderWrapper>
    <Header>
      <h1 style={{ margin: '0' }}>
        <Link
          to="/"
          style={{
            color: 'white',
            textDecoration: 'none',
          }}
        >
          {/* <img
            src={logoinsignia}
            alt="D&D Transportation Services, Inc."
            style={{ width: '48px' }}
          /> */}
          <img
            src={logo}
            alt="D&D Transportation Services"
            style={{ width: '236px', display: 'block' }}
          />
        </Link>
      </h1>
      <NavWrap>
        <Toggle>
          {({ on, toggle }) => (
            <>
              <button onClick={toggle}>Menu</button>
              {on && <MobileNav toggle={toggle} />}
            </>
          )}
        </Toggle>
        <NavContact>
          <a href="tel:+18002237671" rel="nofollow">
            <i className="material-icons">phone_iphone</i>&nbsp;(800) 223-7671
          </a>
          <a href="/contact">
            <i className="material-icons">place</i>
            &nbsp;Request a Quote
          </a>
          <a
            rel="noopener noreferrer"
            href="https://intelliapp.driverapponline.com/c/ddtsi"
            target="_blank"
          >
            <i className="material-icons">chrome_reader_mode</i>&nbsp; Driver
            Application
          </a>
          <a
            rel="noopener noreferrer"
            href="https://ddta.loadtracking.com:8878/im/home"
          >
            <i className="material-icons">lock_outline</i>&nbsp;Login
          </a>
        </NavContact>
        <Nav />
      </NavWrap>
    </Header>
  </HeaderWrapper>
)

export default Menu

const HeaderWrapper = styled.header`
  background: white;
  width: 100%;
  border-bottom: 1px solid ${ltGrey};
`
const Header = styled.div`
  width: 100%;
  display: flex;
  z-index: 0;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  max-width: ${props => props.theme.sizes.maxWidth};
  margin: 0 auto;
  ${above.md`
    flex-direction: row;
    justify-content: space-between;
    button {
      display: none;
    }
  `}
  button {
    color: ${darkGrey};
    font-weight: normal;
    ${sansCondBold};
    font-size: 0.9rem;
    text-transform: uppercase;
    text-decoration: none;
    z-index: 1;
    padding: 8px 16px;
    border: 1px solid ${darkGrey};
    background: transparent;
    position: relative;
    transition: all 0.3s;
    cursor: pointer;
    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      transition: all 0.3s;
      width: 0%;
      height: 100%;
      top: 0;
      left: 0;
      color: ${primary};
      background: ${darkGrey};
    }
    &:hover {
      color: ${primary};
      &::after {
        width: 100%;
      }
    }
  }
`
const NavWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
const NavContact = styled.nav`
  display: none;
  ${above.md`
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
  width: 100%;
  align-items: flex-start;
  margin-bottom: 24px;
  a {
    ${sansCond}
    font-size: 1rem;
    color: ${darkGrey};
    text-transform: uppercase;
    text-decoration: none;
    font-weight: normal;
    transition: 0.3s ease border;
    padding: 0;
    margin-left: 32px;
    &:hover,
    &.active {
      color: ${primary};
    }
  }
  `};
`
