import { applyStyleModifiers } from 'styled-components-modifiers'
import styled from 'styled-components'
import { Link } from 'gatsby-plugin-modal-routing'

import { sansCondBold } from '../utilities'
import { darkGrey, primary, secondary, above } from '../elements'

const MODIFIER_ZONE = {
  dark: () => `
    border-color: ${primary};
    color: ${darkGrey};
    background: ${primary};
    &:after {
      background: ${darkGrey};
    }
    &:hover {
      color: ${primary};
      border-color: ${darkGrey};
    }
  `,
  darkoutline: () => `
    border-color: ${darkGrey};
    color: ${darkGrey};
    background: ${primary};
    &:after {
      background: ${darkGrey};
    }
    &:hover {
      color: ${primary};
      border-color: ${darkGrey};
    }
  `,
  light: () => `
    border-color: white;
    color: white;
    &:after {
      background: white;
    }
    &:hover {
      color: ${secondary};
    }
  `,
  outline: () => `
    border: 1px solid ${primary};
    color: ${primary};
    background: none;
    &:after {
      background: ${primary};
    }
    &:hover {
      color: ${darkGrey};
    }
  `,
  center: () => `
    text-align: center;
  `,
  right: () => `
    float: right;
  `,
  righthanging: () => `
    display: inline-block;
    position: absolute;
    bottom: 1em;
    right: -1em;
  `,
  centerhanging: () => `
    padding-right: 30px;
    padding-left: 30px;
    display: inline-block;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: -1em;
  `,
  lefthanging: () => `
    display: inline-block;
    position: absolute;
    bottom: 1em;
    left: -1em;
  `,
  fullwidth: () => `
    width: 100%;
    text-align: center;
  `,
  marginbottom: () => `
    margin-bottom: 1rem;
  `,
  margintop: () => `
    margin-top: 1rem;
  `,
  marginleft: () => `
    margin-left: 1rem;
  `,
}

export const Button = styled(Link)`
  ${sansCondBold};
  color: ${darkGrey};
  font-size: 1.4rem;
  position: relative;
  /* text-transform: uppercase; */
  text-decoration: none;
  display: inline-block;
  z-index: 0;
  padding: 10px 20px;
  background: ${primary};
  white-space: nowrap;
  transition: all 0.3s;
  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    transition: all 0.3s;
    width: 0%;
    height: 100%;
    top: 0;
    left: 0;
    color: ${primary};
    background: ${darkGrey};
  }
  &:hover {
    color: ${primary};
    &:after {
      width: 100%;
    }
  }
  ${applyStyleModifiers(MODIFIER_ZONE)};
`

export const FormButton = styled.button`
  ${sansCondBold};
  color: ${darkGrey};
  font-size: 1.4rem;
  position: relative;
  /* text-transform: uppercase; */
  text-decoration: none;
  display: inline-block;
  border: 1px solid ${primary};
  z-index: 0;
  padding: 10px 20px;
  background: ${primary};
  white-space: nowrap;
  transition: all 0.3s;
  margin-top: 1rem;
  cursor: pointer;
  & + button {
    margin-left: 1rem;
  }
  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    transition: all 0.3s;
    width: 0%;
    height: 100%;
    top: 0;
    left: 0;
    color: ${primary};
    background: ${darkGrey};
  }
  &:hover {
    color: ${primary};
    border: 1px solid ${darkGrey};
    &:after {
      width: 100%;
    }
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
  }
  ${applyStyleModifiers(MODIFIER_ZONE)};
`

export const InputButton = styled.input`
  ${sansCondBold};
  color: ${darkGrey};
  font-size: 1.4rem;
  position: relative;
  /* text-transform: uppercase; */
  text-decoration: none;
  display: inline-block;
  border: 1px solid ${primary};
  z-index: 0;
  padding: 10px 20px;
  background: ${primary};
  white-space: nowrap;
  transition: all 0.3s;
  margin-top: 1rem;
  cursor: pointer;
  & + button {
    margin-left: 1rem;
  }
  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    transition: all 0.3s;
    width: 0%;
    height: 100%;
    top: 0;
    left: 0;
    color: ${primary};
    background: ${darkGrey};
  }
  &:hover {
    color: ${primary};
    border: 1px solid ${darkGrey};
    &:after {
      width: 100%;
    }
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
  }
  ${applyStyleModifiers(MODIFIER_ZONE)};
`
export const DownloadButton = styled.a`
  ${sansCondBold};
  border: 1px solid ${primary};
  color: ${primary};
  font-size: 1.4rem;
  position: relative;
  /* text-transform: uppercase; */
  text-decoration: none;
  display: inline-block;
  z-index: 0;
  padding: 10px 20px;
  background: none;
  white-space: nowrap;
  transition: all 0.3s;
  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    transition: all 0.3s;
    width: 0%;
    height: 100%;
    top: 0;
    left: 0;
    color: ${primary};
    background: ${primary};
  }
  &:hover {
    color: ${darkGrey};
    &:after {
      width: 100%;
    }
  }
  ${applyStyleModifiers(MODIFIER_ZONE)}
`

export const InlineLink = styled.a`
  ${sansCondBold};
  font-size: 1rem;
  color: ${darkGrey};
  text-transform: uppercase;
  text-decoration: none;
  font-weight: normal;
  transition: 0.3s ease border;
  padding: 0;
  &:hover {
    color: ${primary};
    &:after {
      width: 100%;
    }
  }
`

export const InlineButton = styled(Button)`
  ${sansCondBold};
  font-size: 1rem;
  color: ${darkGrey};
  text-transform: uppercase;
  text-decoration: none;
  font-weight: normal;
  transition: 0.3s ease border;
  padding: 0;
  border: none;
`
