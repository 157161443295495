import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { above, richGrey, grey, mdGrey } from '../elements'
import ita from '../images/affiliates/ita-logo.svg'
import idahoprivate from '../images/affiliates/2018-idaho-private-100-logo.svg'
import smartway from '../images/affiliates/smartway-logo.svg'
import wit from '../images/affiliates/wit-logo.svg'
import mcleod from '../images/affiliates/mcleod-logo.svg'
import tia from '../images/affiliates/tia-logo.svg'

const AwardsAffiliations = () => (
  <Zone>
    <List>
      <ItemHeading className="affiliate-heading">
        <h6>Awards & Affiliations</h6>
        <MoreLink to="/affiliates-awards">
          View All&nbsp;
          <i className="material-icons">chevron_right</i>
        </MoreLink>
      </ItemHeading>
      <Item to="/affiliates-awards">
        <LogoIcon src={smartway} alt="SmartWay Transport Partner" />
      </Item>
      <Item to="/affiliates-awards">
        <LogoIcon src={idahoprivate} alt="Idaho Private 100 Award Winner" />
      </Item>
      <Item to="/affiliates-awards">
        <LogoIcon
          src={tia}
          alt="Transportation Intermediaries Association Member"
        />
      </Item>
      <Item to="/affiliates-awards">
        <LogoIcon src={mcleod} alt="Mcleod Software" />
      </Item>
      <Item to="/affiliates-awards">
        <LogoIcon src={ita} alt="Idaho Trucking Association Member" />
      </Item>
      <Item to="/affiliates-awards">
        <LogoIcon src={wit} alt="Women in Trucking Member" />
      </Item>
    </List>
  </Zone>
)

export default AwardsAffiliations

const LogoIcon = styled.img`
  height: 52px;
  margin-bottom: 10px;
  ${above.lg`
    height: 56px;
    margin-bottom: 10px;
  `}
`
const List = styled.div`
  max-width: ${props => props.theme.sizes.maxWidth};
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
`
const Item = styled(Link)`
  margin-top: 1rem;
  text-decoration: none;
  color: ${grey};
  padding: 0 0.5rem;
  flex: 1 0 46%;
  ${above.sm`
    flex: 1 0 32%;
    `}
  ${above.md`
    flex: 1 1 auto;
  `}
`
const MoreLink = styled(Link)`
  color: ${mdGrey};
  text-decoration: none;
  margin-top: 0;
  text-transform: uppercase;
  font-size: 0.85rem;
`
const ItemHeading = styled.div`
  margin-top: 1rem;
  text-decoration: none;
  color: ${grey};
  padding: 0 1rem;
  flex: 0 0 100%;
  ${above.lg`
    flex: 0 0 auto;
  `}
  h6 {
    margin-bottom: 0.5rem;
  }
`
const Zone = styled.section`
  padding: 1rem 0;
  color: white;
  position: relative;
  text-align: center;
  background: ${richGrey};
  ${above.md`
    padding-left: 1rem;
    padding-right: 1rem;
  `}
`
