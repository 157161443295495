import React, { Component } from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import styled from 'styled-components'
import {
  FormBox,
  FormButton,
  Button,
  primary,
  secondary,
  darkGrey,
  mdGrey,
  xltGrey,
  grey,
} from '../elements'
import { sansCondBold } from '../utilities'

export default class Newsletter extends Component {
  state = { email: null }

  _handleChange = e => {
    console.log({
      [`${e.target.name}`]: e.target.value,
    })
    this.setState({
      [`${e.target.name}`]: e.target.value,
    })
  }

  _handleSubmit = e => {
    e.preventDefault()

    console.log('submit', this.state)

    addToMailchimp(this.state.email, this.state)
      .then(({ msg, result }) => {
        console.log('msg', `${result}: ${msg}`)

        if (result !== 'success') {
          throw msg
        }
        alert(msg)
      })
      .catch(err => {
        console.log('err', err)
        alert(err)
      })
  }

  render() {
    return (
      <FormBox>
        <SignUp>Stay in the Know & Get Industry Insights</SignUp>
        <NewsletterForm onSubmit={this._handleSubmit}>
          <input
            type="text"
            onChange={this._handleChange}
            placeholder="Email Address"
            name="email"
          />
          <SignUpButton modifiers={['outline']} name="submit" type="submit">
            Sign Up
          </SignUpButton>
        </NewsletterForm>
        <Disclaimer>
          By signing up you agree to the <a href="/terms-of-use">terms</a> and{' '}
          <a href="/privacy">privacy</a>
        </Disclaimer>
      </FormBox>
    )
  }
}

const NewsletterForm = styled.form`
  border: 1px solid ${mdGrey};
  display: flex;
  padding: 8px;
  margin-bottom: 0.55rem;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 1000px ${primary} inset !important;
    box-shadow: 0 0 0px 1000px ${primary} inset !important;
  }
  input {
    border: none;
    text-align: left;
    width: 100%;
    color: ${xltGrey};
    background: none;
    &::-webkit-input-placeholder {
      color: ${xltGrey};
    }
    &::-moz-placeholder {
      color: ${xltGrey};
    }
    &:-ms-input-placeholder {
      color: ${xltGrey};
    }
    &:-moz-placeholder {
      color: ${xltGrey};
    }
    &:focus {
      border-color: ${darkGrey};
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 0 3px rgba(174, 172, 170, 0.1);
      outline: none;
      background-color: ${primary};
      color: ${darkGrey};
      padding-left: 1rem;
    }
  }
`
const SignUpButton = styled(FormButton)`
  margin: 0;
  &:hover {
    border: 1px solid ${primary};
  }
`
const SignUp = styled.h3`
  ${sansCondBold};
  transition: all 0.2s;
  font-size: 1rem;
  letter-spacing: 0.05em;
  color: ${grey};
  text-transform: uppercase;
  margin-bottom: 0.65rem;
  text-align: left;
`
const Disclaimer = styled.p`
  font-size: 0.25rem;
  color: ${mdGrey};
  text-align: left;
  a {
    text-decoration: none;
    color: ${mdGrey};
  }
`
