const theme = {
  colors: {
    base: '#2A2927', // Black
    // secondary: '#AEACAA', // Medium Gray
    // tertiary: '#F7F6F5', // Light Gray
    highlight: '#28688B', // Light Blue

    primary: '#F9A825', // D&D Gold
    secondary: '#28688B', // Slate Blue
    tertiary: '#4D7925', // Earth Green
    darkGrey: '#2A2927', // Black
    richGrey: '#3C3A38', // Rich Grey
    grey: '#AEACAA', // Medium Grey
    ltGrey: '#D0D0CE', // Grey
    xltGrey: '#F7F6F5', // Super Light Grey
  },
  sizes: {
    maxWidth: '1440px',
    maxWidthCentered: '650px',
  },
  responsive: {
    small: '35em',
    medium: '50em',
    large: '70em',
  },
}

export default theme
