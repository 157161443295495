import { applyStyleModifiers } from 'styled-components-modifiers'
import { Link } from 'gatsby-plugin-modal-routing'
import styled, { css } from 'styled-components'
import {
  primary,
  secondary,
  darkGrey,
  mdGrey,
  grey,
  tertiary,
  xltGrey,
} from './colors'
import { sans } from '../utilities'
import { above } from './breakpoints'

const MODIFIER_ZONE = {
  darkOverlay: () => `
  h3, p { color: ${xltGrey}; }
    &:before {
      background: ${darkGrey};;
    }
  `,
  blank: () => `
    background: transparent;
    color: ${darkGrey};
  `,
  center: () => `
    text-align: center;
  `,
  short: () => `
    padding: 6rem 10%;
  `,
  primary: () => `
    h1, h2, h3, h4, h5, p { color: ${darkGrey}; }
    &:before {
      background-color: ${primary};
      opacity: 92%;
    }
    h1, h2, h3, h4, h5 {
      a, a:visited, a:hover, a:active {
        text-decoration: none;
        color: ${darkGrey};
      }
    }
  `,
  secondary: () => `
    h3, p { color: ${xltGrey}; }
    &:before {
      background-color: ${secondary};
    }
  `,
  tertiary: () => `
    h2, h3, p {color: ${xltGrey};}
    &:before {
      background-color: ${tertiary};
    }
  `,
  grey: () => `
    background: ${darkGrey};
    color: ${grey};
    h2 {
      color: ${primary};
    }
  `,
  white: () => `
    background: white;
    h3, p { ${grey} }
  `,
  noOverlay: () => `
    h2, h3, p { color: ${xltGrey}; }
    &:before {
      content: none;
    }
  `,
}

export const Zone = styled.section`
  padding: 14rem 10%;
  color: white;
  position: relative;
  h2 {
    font-size: 3.25rem;
    line-height: 3rem;
    color: ${primary};
    ${above.md`
      font-size: 4rem;
      line-height: 3.85rem;
    `};
  }
  h3 {
    font-size: 1.65rem;
    color: ${mdGrey};
    ${above.md`
      font-size: 1.8rem;
      line-height: 2.5rem;
    `};
  }
  p {
    font-size: 1.05rem;
    line-height: 1.65rem;
    margin-bottom: 1.8rem;
    color: ${mdGrey};
    ${above.md`
      font-size: 1.15rem;
    `}
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    z-index: -1;
    transition: 0.3s all ease;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
    background-size: cover;
    ${({ image }) => image && `background-image: url(${image});`};
  }
  &:hover {
    &:before {
      opacity: 0.9;
    }
  }
  ${applyStyleModifiers(MODIFIER_ZONE)};
  ${({ hero }) =>
    hero &&
    css`
      padding-top: 5rem;
      ${above.md`
        padding-top: 6rem;
      `};
    `};
  ${({ mini }) =>
    mini &&
    css`
      padding-bottom: 1rem;
      ${above.md`
        padding-bottom: 1rem;
      `};
    `};
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      padding: 0;
    `};
  ${({ left }) =>
    left &&
    css`
      ${above.md`
        .zone-content {
          width: 60%;
          margin-right: auto;
        }
      `};
    `};
  ${({ right }) =>
    right &&
    css`
      ${above.md`
        .zone-content {
          width: 60%;
          margin-left: auto;
        }
      `};
    `};
  ${({ largeP }) =>
    largeP &&
    css`
      p {
        font-size: 1.35rem;
        line-height: 1.85rem;
        margin-bottom: 1.8rem;
      }
    `};
`

export const BoxWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 0 auto;
  &::after {
    content: '';
    flex: 0 0 32%;
  }
`

export const Box = styled.div`
  position: relative;
  margin: 0 0 2rem 0;
  width: 100%;
  transition: background 0.2s;
  flex: 0 0 100%;
  ${above.md`
    flex: ${props => (props.featured ? '0 0 100%' : '0 0 49%')};
  `};
  ${above.lg`
    flex: ${props => (props.featured ? '0 0 100%' : '0 0 32%')};
  `};
`

export const BoxInner = styled.div`
  position: relative;
  margin: 0;
  padding: 2rem;
  background: white;
  height: 100%;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  transition: background 0.2s;
  &:hover {
    -webkit-box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1),
      0 6px 10px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1),
      0 6px 10px rgba(0, 0, 0, 0.05);
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1), 0 6px 10px rgba(0, 0, 0, 0.05);
  }
`
export const InlineBox = styled.div`
  position: relative;
  margin: 1rem 0;
  padding: 2rem;
  background: white;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0,0,0,.1);
  -moz-box-shadow: 0 1px 3px 0 rgba(0,0,0,.1);
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.1);
  transition: background 0.2s;
  &:hover {
    /* background: ${props => props.theme.colors.xltGrey}; */
    -webkit-box-shadow: 0 6px 18px rgba(0,0,0,0.1), 0 6px 10px rgba(0,0,0,.05);
    -moz-box-shadow: 0 6px 18px rgba(0,0,0,0.1), 0 6px 10px rgba(0,0,0,.05);
    box-shadow: 0 6px 18px rgba(0,0,0,0.1), 0 6px 10px rgba(0,0,0,.05);
  }
`
export const ZoneWrapper = styled.section`
  background: white;
  margin-top: 1rem;
`
export const LinkWrapper = styled(Link)`
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  color: ${props => props.theme.colors.base};
  text-decoration: none;
  .gatsby-image-wrapper {
    height: 0;
    padding-bottom: 60%;
    ${above.md`
      padding-bottom: ${props => (props.featured ? '40%' : '60%')};
    `}
  }
`
export const OverlapBoxInner = styled(BoxInner)`
  margin: -2.5em 1em 0 1em;
`
export const FormBox = styled.section`
  text-align: center;
  p {
    font-size: 1rem;
    ${sans()};
  }
`

export const MaxWidthWrapper = styled.div`
  max-width: ${props => props.theme.sizes.maxWidth};
  margin: 0 auto;
  padding: 0 1.5rem;
  ${above.md`
    display: flex;
  `}
`
