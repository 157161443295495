import styled from 'styled-components'
import { above } from './breakpoints'
import { sansCondBold } from '../utilities'
import { primary, darkGrey } from '../elements'

export const Feature = styled.p`
  font-size: 2.25rem;
  line-height: 1.5;
`
export const LargeP = styled.p`
  font-size: 1.4rem;
  line-height: 2rem;
  color: ${darkGrey};
  ${above.md`
    // font-size: 24px;
  `}
`
export const PageHeader = styled.h1`
  font-size: 3rem;
`
export const CardTitle = styled.h2`
  font-size: 2em;
  margin: 0 0 0.5rem 0;
`
export const CultureTitle = styled.h1`
  ${sansCondBold}
  font-size: 4em;
  width: 100%;
  padding: 0 1rem;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  margin: 0 auto 0.4em auto;
  text-align: center;
  color: ${primary};
`
